// var stickyHeader = false;
// var transformHomepageDonationAmountImages = false;
// var flyoutNav = false;
// var fullscreenNav = false;
// var homeQuickGivingPreFooter = false;
// var countUpStats = false;
// var countUpStatsDuration = 3000;

var homeQuickGivingPreFooter = true;


var subsiteHijackMainNav = ['rulku', 'island-and-cape', 'bukmak', 'dinybulu'];
var subsiteHijackMainLogoClickDestination = ['rulku', 'island-and-cape', 'bukmak', 'dinybulu'];


// Subsites - Social icons redirect
if ($("body").hasClass("subsite") && $("body").hasClass("rulku")) {
  $("ul.socialIcons a.facebook").attr("href", "https://www.facebook.com/RulkuEnterprises/");
}

if ($("body").hasClass("subsite") && $("body").hasClass("island-and-cape")) {
  $("ul.socialIcons a.facebook").attr("href", "https://www.facebook.com/IslandandCape/");
}

if ($("body").hasClass("subsite") && $("body").hasClass("bukmak")) {
  $("ul.socialIcons a.facebook").attr("href", "https://www.facebook.com/BukmakConstructions/");
}

if ($("body").hasClass("subsite") && $("body").hasClass("dinybulu")) {
  $("ul.socialIcons a.facebook").attr("href", "https://www.facebook.com/Dinybulu/ ");
}